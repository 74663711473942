// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-footer-js": () => import("./../../../src/pages/components/Footer.js" /* webpackChunkName: "component---src-pages-components-footer-js" */),
  "component---src-pages-components-info-js": () => import("./../../../src/pages/components/Info.js" /* webpackChunkName: "component---src-pages-components-info-js" */),
  "component---src-pages-components-intro-basic-js": () => import("./../../../src/pages/components/IntroBasic.js" /* webpackChunkName: "component---src-pages-components-intro-basic-js" */),
  "component---src-pages-components-intro-js": () => import("./../../../src/pages/components/Intro.js" /* webpackChunkName: "component---src-pages-components-intro-js" */),
  "component---src-pages-components-request-access-js": () => import("./../../../src/pages/components/RequestAccess.js" /* webpackChunkName: "component---src-pages-components-request-access-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

